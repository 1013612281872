import { useContext, useEffect, useState } from "react";
import Button from "../../Reusables/Button/Button";
import Close from "../../../assets/icons/close.png";
import "../../../assets/css/CustomizePopup/customizePopup.css";
import { AppContext } from "../../../Context/Context";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../util/helper/helperFunctions";

const CustomizePopup = () => {
  //Context API
  const { customizePopup, setCustomizePopup, plantConfig } =
    useContext(AppContext);
  const { productionLineViewIsOpen, setProductionLineViewIsOpen } =
    useContext(AppContext);
  const { removedLgvViewIsOpen, setRemovedLgvViewIsOpen } =
    useContext(AppContext);
  const { dockDoorViewIsOpen, setDockDoorViewIsOpen } = useContext(AppContext);
  const { lgvObjectViewIsOpen, setLgvObjectViewIsOpen } =
    useContext(AppContext);
  const { dockDoorMode, setDockDoorMode } = useContext(AppContext);
  const { enableProductionLine, setEnableProductionLine } =
    useContext(AppContext);
  const { enableDockDoor, setEnableDockDoor } = useContext(AppContext);

  const { destinationPointersViewIsOpen, setDestinationPointersViewIsOpen } =
    useContext(AppContext);
  const { enableDestinationPointers, setEnableDestinationPointers } =
    useContext(AppContext);

  const {
    enableLabelledMap,
    setEnableLabelledMap,
    enableBatteryStationObj,
    setEnableBatteryStationObj,
  } = useContext(AppContext);

  const [dashSwitches, setDashSwitches] = useState({
    productionLines: true,
    removedLgv: true,
    dockDoors: true,
    lgvObjects: true,
    destinationLines: false,
    dockDoorMode: false,
    labelledMap: false,
    blockStorage: false,
    batteryStationObj: true,
  });

  useEffect(() => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      lgvObjects: getLocalStorageItem("enableLgvMarkers"),
    }));
  }, [getLocalStorageItem("enableLgvMarkers")]);

  useEffect(() => {
    if (localStorage.getItem("appSettings")) {
      let prevSwitchState = JSON.parse(localStorage.getItem("appSettings"));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        productionLines: prevSwitchState.productionLines,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        removedLgv: prevSwitchState.removedLgv,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        dockDoors: prevSwitchState.dockDoors,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        dockDoorMode: prevSwitchState.dockDoorMode,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        destinationLines: prevSwitchState.destinationLines,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        labelledMap: prevSwitchState.labelledMap,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        blockStorage: prevSwitchState.blockStorage,
      }));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        batteryStationObj: prevSwitchState.batteryStationObj,
      }));
      // setDashSwitches((dashSwitches) => ({...dashSwitches, lgvObjects: prevSwitchState.lgvObjects}));
      setDashSwitches((dashSwitches) => ({
        ...dashSwitches,
        lgvObjects: getLocalStorageItem("enableLgvMarkers"),
      }));
    } else {
      setDashSwitches({
        productionLines: true,
        removedLgv: true,
        dockDoors: true,
        lgvObjects: true,
        destinationLines: false,
        dockDoorMode: false,
        labelledMap: false,
        blockStorage: false,
        batteryStationObj: false,
      });
    }
  }, []);

  const disableAll = () => {
    setDashSwitches({
      productionLines: false,
      removedLgv: false,
      dockDoorMode: false,
      dockDoors: false,
      lgvObjects: false,
      destinationLines: false,
      labelledMap: false,
      blockStorage: false,
      batteryStationObj: false,
    });
    localStorage.setItem("appSettings", JSON.stringify(dashSwitches));
    localStorage.setItem("dockMode", JSON.stringify(false));
    setLgvObjectViewIsOpen(false);
    setProductionLineViewIsOpen(false);
    setRemovedLgvViewIsOpen(false);
    setDockDoorViewIsOpen(false);
    setDockDoorMode(false);
    setEnableProductionLine(false);
    setLocalStorageItem("enableLgvMarkers", false);
    setEnableDockDoor(false);
    setEnableDestinationPointers(false);
    setEnableBatteryStationObj(false);
    setEnableLabelledMap(false);
  };

  const enableAll = () => {
    setDashSwitches({
      productionLines: true,
      removedLgv: true,
      dockDoorMode: true,
      dockDoors: true,
      lgvObjects: true,
      destinationLines: true,
      labelledMap: true,
      blockStorage: true,
      batteryStationObj: true,
    });
    localStorage.setItem("appSettings", JSON.stringify(dashSwitches));
    localStorage.setItem("dockMode", JSON.stringify(true));
    setLgvObjectViewIsOpen(true);
    setProductionLineViewIsOpen(true);
    setRemovedLgvViewIsOpen(true);
    setDockDoorViewIsOpen(true);
    setDockDoorMode(true);
    setEnableProductionLine(true);
    setLocalStorageItem("enableLgvMarkers", true);
    setEnableDockDoor(true);
    setEnableDestinationPointers(true);
    setEnableBatteryStationObj(true);
    setEnableLabelledMap(true);
  };

  //Toggle Switches
  const productionLineToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      productionLines: !dashSwitches.productionLines,
    }));
    setProductionLineViewIsOpen(!productionLineViewIsOpen);
    setEnableProductionLine(!enableProductionLine);
  };

  const removedLgvToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      removedLgv: !dashSwitches.removedLgv,
    }));
    setRemovedLgvViewIsOpen(!removedLgvViewIsOpen);
  };

  const dockDoorsToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      dockDoors: !dashSwitches.dockDoors,
    }));
    setDockDoorViewIsOpen(!dockDoorViewIsOpen);
    setEnableDockDoor(!enableDockDoor);
  };

  const lgvObjectsToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      lgvObjects: !dashSwitches.lgvObjects,
    }));
    if (lgvObjectViewIsOpen) {
      setLgvObjectViewIsOpen(false);
    } else {
      setLgvObjectViewIsOpen(true);
    }
    if (getLocalStorageItem("enableLgvMarkers")) {
      setLocalStorageItem("enableLgvMarkers", false);
    } else {
      setLocalStorageItem("enableLgvMarkers", true);
    }
  };

  const destinationLineToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      destinationLines: !dashSwitches.destinationLines,
    }));
    setDestinationPointersViewIsOpen(!destinationPointersViewIsOpen);
    setEnableDestinationPointers(!enableDestinationPointers);
  };

  const dockDoorModeToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      dockDoorMode: !dashSwitches.dockDoorMode,
    }));
    setDockDoorMode(!dockDoorMode);
  };

  const blockStorageToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      blockStorage: !dashSwitches.blockStorage,
    }));
    setEnableLabelledMap(!enableLabelledMap);
  };

  const batteryStationToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      batteryStationObj: !dashSwitches.batteryStationObj,
    }));
    setEnableBatteryStationObj(!enableBatteryStationObj);
  };

  const labelledMapToggleSwitch = () => {
    setDashSwitches((dashSwitches) => ({
      ...dashSwitches,
      labelledMap: !dashSwitches.labelledMap,
    }));
    // setEnableLabelledMap(!enableLabelledMap);
  };

  //Check for Dock door Mode
  useEffect(() => {
    localStorage.setItem("dockMode", JSON.stringify(dockDoorMode));
  }, [dockDoorMode]);

  //Check and update Switches
  useEffect(() => {
    if (dashSwitches) {
      localStorage.setItem("appSettings", JSON.stringify(dashSwitches));
    }
  }, [dashSwitches]);

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("customize-wrapper-id")) {
      setCustomizePopup(false);
    }
  });

  const getLocationFromLocal = () => {
    if (getLocalStorageItem("locationCode")) {
      return getLocalStorageItem("locationCode");
    }
  };

  return (
    <div id="customize-wrapper-id" className="customize-wrapper">
      <div className="customize-container">
        <div className="customize-modal-top-section">
          <img onClick={() => setCustomizePopup(!customizePopup)} src={Close} />
        </div>
        <div className="customize-modal-middle-section">
          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => productionLineToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.productionLines}
                value={dashSwitches.productionLines}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>Production Lines</p>
          </div>
          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => removedLgvToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.removedLgv}
                value={dashSwitches.removedLgv}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>Removed LGVs</p>
          </div>
          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => dockDoorsToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.dockDoors}
                value={dashSwitches.dockDoors}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>Dock Doors</p>
          </div>
          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => lgvObjectsToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.lgvObjects}
                value={dashSwitches.lgvObjects}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>LGV Objects</p>
          </div>
          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => destinationLineToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.destinationLines}
                value={dashSwitches.destinationLines}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>Destination Pointer Lines</p>
          </div>
          {plantConfig.indoorMap?.batteryStations?.isEnabled && (
            <div className="customize-buttons">
              <label className="switch">
                <input
                  onClick={() => batteryStationToggleSwitch()}
                  type="checkbox"
                  checked={dashSwitches.batteryStationObj}
                  value={dashSwitches.batteryStationObj}
                ></input>
                <span className="slider round"></span>
              </label>
              <p>Battery Station Objects</p>
            </div>
          )}

          <div className="customize-buttons">
            <label className="switch">
              <input
                onClick={() => dockDoorModeToggleSwitch()}
                type="checkbox"
                checked={dashSwitches.dockDoorMode}
                value={dashSwitches.dockDoorMode}
              ></input>
              <span className="slider round"></span>
            </label>
            <p>
              {!dashSwitches.dockDoorMode
                ? "Dock Door Simple Mode"
                : "Dock Door Complex Mode"}
            </p>
          </div>
          {/* {getLocationFromLocal() === "ATL" ||
          getLocationFromLocal() === "ALA" ||
          getLocationFromLocal() === "LAS" ||
          getLocationFromLocal() === "HAZ" ? (
            <div className="customize-buttons">
              <label className="switch">
                <input
                  onClick={() => labelledMapToggleSwitch()}
                  type="checkbox"
                  checked={dashSwitches.labelledMap}
                  value={dashSwitches.labelledMap}
                ></input>
                <span className="slider round"></span>
              </label>
              <p>Labelled Map</p>
            </div>
          ) : null} */}
        </div>
        <div className="customize-modal-bottom-section">
          <Button
            onClick={() => disableAll()}
            btnClass="cus-btn"
            placeholder="Disable All"
          ></Button>
          <Button
            onClick={() => enableAll()}
            btnClass="cus-btn"
            placeholder="Enable All"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default CustomizePopup;
